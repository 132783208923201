<template>
  <v-container class="px-0">
    <v-row no-gutters>
      <v-col cols="12" class="pa-0 ma-0">
        <h1 class="font-weight-regular">{{ cartItems.length }} items in your basket</h1>
      </v-col>
      <v-col cols="8" class="pa-0 ma-0">
        <v-list v-if="cartItems.length" class="pa-0 ma-0">
		 <template  v-for="(product, key) in cartItems" >
		  <v-divider v-if="key !== 0"></v-divider>
		  <v-list-item :key="key" class="pa-0 ma-0">


		   <card-item :product="product"/>

		   <!--            <v-list-item-avatar>
						 <img :src="product.image_url"/>
					   </v-list-item-avatar>
					   <v-row>
						 <v-col class="pb-0 pt-0" cols="12">
						   <v-list-item-title>{{ product.name }}</v-list-item-title>
						 </v-col>
						 <v-col class="pb-0 pt-0" cols="12">
						   <v-list-item-subtitle>Price: R {{ product.price }}</v-list-item-subtitle>
						 </v-col>
					   </v-row>-->
		  </v-list-item>
		 </template>
        </v-list>
        <v-card-text class="text-center" v-else>
          Your shopping cart is empty.
        </v-card-text>
      </v-col>
      <v-spacer/>
      <v-col cols="4" class="pl-13">
        <v-card class="pa-5">
          <h4>How you'll pay</h4>
          <v-radio-group v-model="paymentMethod">
            <v-row>
              <v-col class="d-flex justify-start align-center">
                <v-radio>
                  <template v-slot:label>
                    <v-btn class="mx-1" outlined color="var(--color-gray)" max-width="50px" height="35px">
                      <v-img contain max-width="40px" src="@/assets/images/master card-01.svg"></v-img>
                    </v-btn>
                    <v-btn class="mx-1" outlined color="var(--color-gray)" max-width="50px" height="35px">
                      <v-img contain max-width="40px" src="@/assets/images/visa-01.svg"></v-img>
                    </v-btn>
                    <v-btn class="mx-1" outlined color="var(--color-gray)" max-width="50px" height="35px">
                      <v-img max-height="30px" max-width="40px" src="@/assets/images/american express-01.svg"></v-img>
                    </v-btn>
                  </template>
                </v-radio>
              </v-col>
            </v-row>
<!--            <v-row class="mt-1">-->
<!--              <v-col class="d-flex justify-start align-center">-->
<!--                <v-radio>-->
<!--                  <template v-slot:label>-->
<!--                    <v-btn class="mx-1" outlined color="var(&#45;&#45;color-gray)" max-width="50px" height="35px">-->
<!--                      <v-img contain max-width="40px" src="@/assets/images/paypal-01.svg"></v-img>-->
<!--                    </v-btn>-->
<!--                  </template>-->
<!--                </v-radio>-->
<!--              </v-col>-->
<!--            </v-row>-->
          </v-radio-group>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between">
              <div>Item(s) total</div>
              <div>R {{ totalAmount }}</div>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between">
              <div class="font-weight-bold">Subtotal</div>
              <div>R {{ totalAmount }}</div>
            </v-col>
          </v-row>
<!--          <v-row>-->
<!--            <v-col cols="12" class="d-flex justify-space-between">-->
<!--              <div>Delivery</div>-->
<!--              <div>-R 4.39</div>-->
<!--            </v-col>-->
<!--          </v-row>-->
          <v-divider class="my-5"></v-divider>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between font-weight-bold">
              <div>Total ( {{ cartItems.length }} items)</div>
              <div>R {{ totalAmount }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn width="100%" x-large color="primary" :to="{name: 'order.checkout'}">Proceed to checkout</v-btn>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import CardItem from "@/components/products/CardItem";

export default {
  name: "Cart",
  components: {CardItem},
  data() {
    return {
      paymentMethod: 0
    }
  },
  computed: {
    ...mapState({
      cartItems: state => {
        return state.cart.items;
      }
    }),
    totalAmount() {
      let sum = 0;
      this.cartItems.forEach((item) => {
		sum += parseInt(item.price);
	  });
      return sum;
	}
  },
}
</script>

<style scoped lang="scss">

::v-deep .round {
  position: relative;

  & label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  & label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  & input[type="checkbox"] {
    visibility: hidden;
  }

  & input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  & input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}
</style>
