<template>
  <v-container class="px-0">
    <v-row no-gutters>
      <v-col cols="3" class="px-0 mx-0">
        <v-img class="rounded" style="max-width: 270px" :src="product.image_url"/>
      </v-col>
      <v-col cols="9">
        <div class="pl-15">
          <h3>
            {{ product.name }}
          </h3>
          <div class="ISBN" v-if="product.isbn">{{ product.isbn }}</div>

          <div class="my-sm-5 my-lg-15"></div>

          <v-row no-gutters justify="space-between">
<!--            <v-col cols="3">-->
<!--              <v-combobox-->
<!--                height="3.4em"-->
<!--                id="quantity"-->
<!--                v-model="quantity"-->
<!--                :items="[1,2,3,4,5,6,7,8,9,10]"-->
<!--                label="Quantity"-->
<!--                dense-->
<!--                outlined-->
<!--                class="rounded-lg px-2"-->
<!--              >-->
<!--              </v-combobox>-->
<!--            </v-col>-->
            <v-col cols="3">
              <div  class="mt-1">
<!--                <div class="pa-0 old_price">-->
<!--                  <del>was R <span>{{ (parseFloat(product.price) + parseFloat(product.price) * .2).toFixed(2)  }}</span></del>-->
<!--                  (20% off)-->
<!--                </div>-->
                <div class="price">R {{ product.price }}</div>
              </div>

            </v-col>
<!--            <v-col cols="3">-->
<!--              <div class="mt-3">-->
<!--              <v-icon>mdi-check-circle-outline</v-icon>-->
<!--              <span class="font-weight-bold">{{ product.quantity }} In stock</span>-->
<!--              </div>-->
<!--            </v-col>-->

          </v-row>
          <v-row class="mt-2">
            <v-col>
			 <state-indicate v-slot:default="{usePromise, isLoading}">
			  <v-btn
				:loading="isLoading"
				@click="usePromise(removeFromCart({product}))"
				color="error"
				class="rounded"
			  >
			   <v-icon dark>
				mdi-close
			   </v-icon>
			   Delete
			  </v-btn>
			 </state-indicate>
<!--              <v-btn small class="ml-5">-->
<!--                save for later-->
<!--              </v-btn>-->
            </v-col>

          </v-row>
          <!--
                <v-btn :loading="addToCartProgress"
                       :disabled="addToCartProgress"
                       x-large
                       width="380px"
                       color="primary"
                       class="mt-3 rounded-lg"
                       @click="handleAddToCart"
                >
                  add to cart
                </v-btn>
                -->
        </div>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: "CardItem",
  props: ['product'],
  data() {
    return {
      quantity: 1,
    }
  },
  methods: {
    ...mapActions({
      removeFromCart: 'cart/removeFromCart',
    }),
  }
}
</script>

<style scoped lang="scss">
.ISBN {
  &:before {
    content: "ISBN: ";
    font-weight: bold;
  }
}

.price {
  font-size: 1.2em;
  font-weight: bold;
}

.old_price {
  font-size: .7em;
}

::v-deep .v-text-field--outlined fieldset {
  border: 2px solid var(--color-blue);
}
</style>
